import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide26/dataPageGuide26"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        ¿Cómo implementar la inteligencia comercial en telemarketing?
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/inteligencia-comercial/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Aprende a implementar correctamente la inteligencia comercial dentro de tus campañas de telemarketing para tener mejores resultados."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="¿Cómo implementar la inteligencia comercial en telemarketing?"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/inteligencia-comercial/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia26.jpg`}
      />
      <meta
        property="og:description"
        content="Aprende a implementar correctamente la inteligencia comercial dentro de tus campañas de telemarketing para tener mejores resultados."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Telemarketing"
      location={location}
      typeGuide="guide26"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
